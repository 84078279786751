export const animals = [
    { name: 'alligator', likes: 0 },
    { name: 'alpaca', likes: 0 },
    { name: 'ant', likes: 0 },
    { name: 'anteater', likes: 0 },
    { name: 'antelope', likes: 0 },
    { name: 'ape', likes: 0 },
    { name: 'armadillo', likes: 0 },
    { name: 'baboon', likes: 0 },
    { name: 'badger', likes: 0 },
    { name: 'bat', likes: 0 },
    { name: 'bear', likes: 0 },
    { name: 'beaver', likes: 0 },
    { name: 'bison', likes: 0 },
    { name: 'boar', likes: 0 },
    { name: 'buffalo', likes: 0 },
    { name: 'camel', likes: 0 },
    { name: 'capybara', likes: 0 },
    { name: 'caribou', likes: 0 },
    { name: 'cat', likes: 0 },
    { name: 'cattle', likes: 0 },
    { name: 'chamois', likes: 0 },
    { name: 'cheetah', likes: 0 },
    { name: 'chimpanzee', likes: 0 },
    { name: 'chinchilla', likes: 0 },
    { name: 'coyote', likes: 0 },
    { name: 'crocodile', likes: 0 },
    { name: 'deer', likes: 0 },
    { name: 'dog', likes: 0 },
    { name: 'donkey', likes: 0 },
    { name: 'echidna', likes: 0 },
    { name: 'eland', likes: 0 },
    { name: 'elephant', likes: 0 },
    { name: 'elk', likes: 0 },
    { name: 'ferret', likes: 0 },
    { name: 'fox', likes: 0 },
    { name: 'frog', likes: 0 },
    { name: 'gaur', likes: 0 },
    { name: 'gazelle', likes: 0 },
    { name: 'gerbil', likes: 0 },
    { name: 'giant-panda', likes: 0 },
    { name: 'giraffe', likes: 0 },
    { name: 'gnu', likes: 0 },
    { name: 'goat', likes: 0 },
    { name: 'goose', likes: 0 },
    { name: 'gorilla', likes: 0 },
    { name: 'guanaco', likes: 0 },
    { name: 'guinea-pig', likes: 0 },
    { name: 'hamster', likes: 0 },
    { name: 'hare', likes: 0 },
    { name: 'hedgehog', likes: 0 },
    { name: 'herring', likes: 0 },
    { name: 'hippopotamus', likes: 0 },
    { name: 'horse', likes: 0 },
    { name: 'human', likes: 0 },
    { name: 'hyena', likes: 0 },
    { name: 'ibex', likes: 0 },
    { name: 'jackal', likes: 0 },
    { name: 'jaguar', likes: 0 },
    { name: 'kangaroo', likes: 0 },
    { name: 'koala', likes: 0 },
    { name: 'komodo-dragon', likes: 0 },
    { name: 'kouprey', likes: 0 },
    { name: 'kudu', likes: 0 },
    { name: 'lemur', likes: 0 },
    { name: 'leopard', likes: 0 },
    { name: 'lion', likes: 0 },
    { name: 'llama', likes: 0 },
    { name: 'loris', likes: 0 },
    { name: 'manatee', likes: 0 },
    { name: 'mandrill', likes: 0 },
    { name: 'marten', likes: 0 },
    { name: 'meerkat', likes: 0 },
    { name: 'mink', likes: 0 },
    { name: 'mole', likes: 0 },
    { name: 'mongoose', likes: 0 },
    { name: 'monkey', likes: 0 },
    { name: 'moose', likes: 0 },
    { name: 'mouse', likes: 0 },
    { name: 'mule', likes: 0 },
    { name: 'okapi', likes: 0 },
    { name: 'opossum', likes: 0 },
    { name: 'oryx', likes: 0 },
    { name: 'otter', likes: 0 },
    { name: 'ox', likes: 0 },
    { name: 'oyster', likes: 0 },
    { name: 'panther', likes: 0 },
    { name: 'pig', likes: 0 },
    { name: 'polar-bear', likes: 0 },
    { name: 'pony', likes: 0 },
    { name: 'porcupine', likes: 0 },
    { name: 'porpoise', likes: 0 },
    { name: 'prairie-dog', likes: 0 },
    { name: 'rabbit', likes: 0 },
    { name: 'raccoon', likes: 0 },
    { name: 'ram', likes: 0 },
    { name: 'rat', likes: 0 },
    { name: 'raven', likes: 0 },
    { name: 'red-deer', likes: 0 },
    { name: 'red-panda', likes: 0 },
    { name: 'reindeer', likes: 0 },
    { name: 'rhinoceros', likes: 0 },
    { name: 'salamander', likes: 0 },
    { name: 'sea-lion', likes: 0 },
    { name: 'seal', likes: 0 },
    { name: 'sheep', likes: 0 },
    { name: 'skunk', likes: 0 },
    { name: 'spider', likes: 0 },
    { name: 'squirrel', likes: 0 },
    { name: 'tapir', likes: 0 },
    { name: 'tarsier', likes: 0 },
    { name: 'tiger', likes: 0 },
    { name: 'wallaby', likes: 0 },
    { name: 'walrus', likes: 0 },
    { name: 'water-buffalo', likes: 0 },
    { name: 'weasel', likes: 0 },
    { name: 'wolf', likes: 0 },
    { name: 'wombat', likes: 0 },
    { name: 'yak', likes: 0 },
    { name: 'zebra', likes: 0 },
];

export const birds = [
    { name: 'Crow', likes: 0 },
    { name: 'Peacock', likes: 0 },
    { name: 'Dove', likes: 0 },
    { name: 'Sparrow', likes: 0 },
    { name: 'Goose', likes: 0 },
    { name: 'Ostrich', likes: 0 },
    { name: 'Pigeon', likes: 0 },
    { name: 'Turkey', likes: 0 },
    { name: 'Hawk', likes: 0 },
    { name: 'Bald eagle', likes: 0 },
    { name: 'Raven', likes: 0 },
    { name: 'Parrot', likes: 0 },
    { name: 'Flamingo', likes: 0 },
    { name: 'Seagull', likes: 0 },
    { name: 'Swallow', likes: 0 },
    { name: 'Blackbird', likes: 0 },
    { name: 'Penguin', likes: 0 },
    { name: 'Robin', likes: 0 },
    { name: 'Swan', likes: 0 },
    { name: 'Owl', likes: 0 },
    { name: 'Stork', likes: 0 },
    { name: 'Woodpecker', likes: 0 },
];
